.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiTooltip-tooltip {
  background-color: #ffffff !important;
  font-size: 12px !important;
  min-width: 60px;
  white-space: nowrap;
  padding: 0 !important;
  border-radius: 0 !important;
  color: #39383a !important;
  border: 1px solid #39383a;
}

.MuiTooltip-tooltip div {
  margin: 6px;
  background-color: #f8fafc;
  padding: 6px;
}

.MuiTooltip-tooltip div span {
  color: #999999
}

/* #pdf-controls {
  display: none;
} */

#pdf-download{
  display: none;
}

#pdf-zoom-reset {
  display: none;
}

#header-bar{
  display: none;
}

@media print {
  @page {
    size: auto A4;
    margin: 0;
  }
  #root,
  h2.MuiTypography-root,
  .modal-file-detail,
  #pdf-controls {
    display: none;
  }
}

#pdf-renderer::-webkit-scrollbar {
  background-color: rgba(30, 79, 79, 0.25);
}

#pdf-renderer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: grey;
}

/* .maximizable-container {
  display: flex;
  flex-direction: row;
}
*/
.maximizable-container.fullscreen {
  padding: 0;
}

/*
.maximizable-container.fullscreen .maximizable-content {
  width: 100%;
}

.maximizable-actions {
  width: 5rem;
  margin-left: -4rem;
  margin-top: -1rem;
}

.maximizable-container.fullscreen .maximizable-actions {
  width: auto;
  margin-left: auto;
}

.maximizable-actions button {
  background-color: white;
} */


.maximizable-container:fullscreen #react-doc-viewer {
  max-width: 100vw;
  height: calc(100vh - 40px);
}